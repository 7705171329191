._19itglyw{border:none}
._1q1l108i{--ds-elevation-surface-current:var(--ds-surface-hovered,#f1f2f4)}
._1q1l16qz{--ds-elevation-surface-current:var(--ds-surface-overlay-hovered,#f1f2f4)}
._1q1l1a0f{--ds-elevation-surface-current:var(--ds-surface-raised-hovered,#f1f2f4)}
._1q1l1bhr{--ds-elevation-surface-current:var(--ds-surface-overlay,#fff)}
._1q1l1ji5{--ds-elevation-surface-current:var(--ds-surface-pressed,#dcdfe4)}
._1q1lf7u5{--ds-elevation-surface-current:var(--ds-surface-raised-pressed,#dcdfe4)}
._1q1lhp5a{--ds-elevation-surface-current:var(--ds-surface-raised,#fff)}
._1q1lqbzx{--ds-elevation-surface-current:var(--ds-surface-sunken,#f7f8f9)}
._1q1lvq0a{--ds-elevation-surface-current:var(--ds-surface-overlay-pressed,#dcdfe4)}
._1q1lvuon{--ds-elevation-surface-current:var(--ds-surface,#fff)}
._1q5112x7{padding-block-start:var(--ds-space-075,6px)}
._1q511b66{padding-block-start:var(--ds-space-050,4px)}
._1q511ejb{padding-block-start:var(--ds-space-300,24px)}
._1q511epz{padding-block-start:var(--ds-space-1000,5pc)}
._1q511jfw{padding-block-start:var(--ds-space-500,40px)}
._1q51oahv{padding-block-start:var(--ds-space-600,3pc)}
._1q51pxbi{padding-block-start:var(--ds-space-200,1pc)}
._1q51u2gc{padding-block-start:var(--ds-space-100,8px)}
._1q51utpp{padding-block-start:var(--ds-space-150,9pt)}
._1q51v47k{padding-block-start:var(--ds-space-250,20px)}
._1q51v77o{padding-block-start:var(--ds-space-025,2px)}
._1q51xncg{padding-block-start:var(--ds-space-800,4pc)}
._1q51xy5q{padding-block-start:var(--ds-space-400,2pc)}
._1q51ze3t{padding-block-start:var(--ds-space-0,0)}
._85i512x7{padding-block-end:var(--ds-space-075,6px)}
._85i51b66{padding-block-end:var(--ds-space-050,4px)}
._85i51ejb{padding-block-end:var(--ds-space-300,24px)}
._85i51epz{padding-block-end:var(--ds-space-1000,5pc)}
._85i51jfw{padding-block-end:var(--ds-space-500,40px)}
._85i5oahv{padding-block-end:var(--ds-space-600,3pc)}
._85i5pxbi{padding-block-end:var(--ds-space-200,1pc)}
._85i5u2gc{padding-block-end:var(--ds-space-100,8px)}
._85i5utpp{padding-block-end:var(--ds-space-150,9pt)}
._85i5v47k{padding-block-end:var(--ds-space-250,20px)}
._85i5v77o{padding-block-end:var(--ds-space-025,2px)}
._85i5xncg{padding-block-end:var(--ds-space-800,4pc)}
._85i5xy5q{padding-block-end:var(--ds-space-400,2pc)}
._85i5ze3t{padding-block-end:var(--ds-space-0,0)}
._bfhk100f{background-color:var(--ds-background-accent-red-bolder-hovered,#ae2e24)}
._bfhk108c{background-color:var(--ds-background-accent-teal-subtlest-hovered,#c6edfb)}
._bfhk108i{background-color:var(--ds-surface-hovered,#f1f2f4)}
._bfhk10ai{background-color:var(--ds-background-accent-teal-subtle-pressed,#c6edfb)}
._bfhk10xm{background-color:var(--ds-blanket,#091e427d)}
._bfhk11bn{background-color:var(--ds-background-accent-gray-subtlest,#f1f2f4)}
._bfhk11dh{background-color:var(--ds-background-warning-pressed,#f5cd47)}
._bfhk12e3{background-color:var(--ds-background-accent-green-subtle-hovered,#7ee2b8)}
._bfhk12j4{background-color:var(--ds-background-accent-gray-subtlest-hovered,#dcdfe4)}
._bfhk14wj{background-color:var(--ds-background-information-bold,#0c66e4)}
._bfhk15ej{background-color:var(--ds-background-neutral-hovered,#091e4224)}
._bfhk15mt{background-color:var(--ds-background-brand-boldest,#1c2b41)}
._bfhk166n{background-color:var(--ds-background-neutral-subtle-hovered,#091e420f)}
._bfhk16e6{background-color:var(--ds-background-accent-blue-subtler-hovered,#85b8ff)}
._bfhk16qz{background-color:var(--ds-surface-overlay-hovered,#f1f2f4)}
._bfhk17e6{background-color:var(--ds-background-accent-yellow-subtle-hovered,#e2b203)}
._bfhk17jr{background-color:var(--ds-background-brand-boldest-pressed,#05c)}
._bfhk18hz{background-color:var(--ds-background-selected-bold-hovered,#05c)}
._bfhk190i{background-color:var(--ds-background-warning-bold,#f5cd47)}
._bfhk19g9{background-color:var(--ds-background-accent-yellow-subtlest-hovered,#f8e6a0)}
._bfhk19ru{background-color:var(--ds-background-selected-pressed,#85b8ff)}
._bfhk1a0f{background-color:var(--ds-surface-raised-hovered,#f1f2f4)}
._bfhk1a17{background-color:var(--ds-background-accent-teal-subtler,#c6edfb)}
._bfhk1a2l{background-color:var(--ds-background-accent-gray-subtle-hovered,#b3b9c4)}
._bfhk1amz{background-color:var(--ds-background-information-bold-hovered,#05c)}
._bfhk1azz{background-color:var(--ds-background-accent-green-bolder-pressed,#164b35)}
._bfhk1b17{background-color:var(--ds-background-accent-lime-bolder-pressed,#37471f)}
._bfhk1b6k{background-color:var(--ds-background-neutral-bold-pressed,#172b4d)}
._bfhk1bhr{background-color:var(--ds-surface-overlay,#fff)}
._bfhk1bsc{background-color:var(--ds-background-neutral-bold-hovered,#2c3e5d)}
._bfhk1cwi{background-color:var(--ds-background-accent-blue-bolder-hovered,#05c)}
._bfhk1d44{background-color:var(--ds-background-accent-yellow-subtler-pressed,#e2b203)}
._bfhk1d5g{background-color:var(--ds-background-input-hovered,#f7f8f9)}
._bfhk1dty{background-color:var(--ds-background-neutral-subtle-pressed,#091e4224)}
._bfhk1e5c{background-color:var(--ds-background-accent-green-bolder,#1f845a)}
._bfhk1eds{background-color:var(--ds-background-accent-lime-subtler-hovered,#b3df72)}
._bfhk1een{background-color:var(--ds-background-accent-magenta-subtlest-pressed,#f797d2)}
._bfhk1f4s{background-color:var(--ds-background-accent-blue-subtle-hovered,#85b8ff)}
._bfhk1f7l{background-color:var(--ds-background-accent-gray-subtler-hovered,#b3b9c4)}
._bfhk1fno{background-color:var(--ds-background-accent-lime-subtlest-pressed,#b3df72)}
._bfhk1fvw{background-color:var(--ds-background-selected-bold,#0c66e4)}
._bfhk1g3m{background-color:var(--ds-background-accent-orange-subtlest,#fff3eb)}
._bfhk1g6e{background-color:var(--ds-background-accent-orange-subtle,#fea362)}
._bfhk1gii{background-color:var(--ds-background-inverse-subtle-pressed,#00000052)}
._bfhk1gly{background-color:var(--ds-background-danger,#ffeceb)}
._bfhk1hgz{background-color:var(--ds-background-accent-lime-subtler-pressed,#94c748)}
._bfhk1i4c{background-color:var(--ds-background-warning-bold-hovered,#e2b203)}
._bfhk1i6i{background-color:var(--ds-background-accent-red-subtlest-hovered,#ffd5d2)}
._bfhk1io2{background-color:var(--ds-background-danger-pressed,#fd9891)}
._bfhk1j2h{background-color:var(--ds-background-accent-teal-subtlest-pressed,#9dd9ee)}
._bfhk1j89{background-color:var(--ds-background-accent-blue-subtle,#579dff)}
._bfhk1j9a{background-color:var(--ds-background-input,#fff)}
._bfhk1jgi{background-color:var(--ds-background-success-hovered,#baf3db)}
._bfhk1ji5{background-color:var(--ds-surface-pressed,#dcdfe4)}
._bfhk1jkz{background-color:var(--ds-background-accent-purple-subtler,#dfd8fd)}
._bfhk1lb4{background-color:var(--ds-background-accent-gray-subtlest-pressed,#b3b9c4)}
._bfhk1lml{background-color:var(--ds-background-accent-orange-subtler-hovered,#fec195)}
._bfhk1lri{background-color:var(--ds-background-discovery-bold,#6e5dc6)}
._bfhk1mi6{background-color:var(--ds-background-accent-magenta-subtle,#e774bb)}
._bfhk1nvp{background-color:var(--ds-background-discovery,#f3f0ff)}
._bfhk1oew{background-color:var(--ds-background-accent-orange-subtle-hovered,#fec195)}
._bfhk1ok8{background-color:var(--ds-background-accent-magenta-bolder-hovered,#943d73)}
._bfhk1ovh{background-color:var(--ds-background-accent-magenta-subtle-pressed,#fdd0ec)}
._bfhk1ozg{background-color:var(--ds-background-accent-yellow-subtlest,#fff7d6)}
._bfhk1p19{background-color:var(--ds-background-accent-green-subtler-pressed,#4bce97)}
._bfhk1pbw{background-color:var(--ds-background-accent-red-subtlest-pressed,#fd9891)}
._bfhk1phk{background-color:var(--ds-background-accent-orange-subtle-pressed,#fedec8)}
._bfhk1q4l{background-color:var(--ds-background-success-bold-hovered,#216e4e)}
._bfhk1r0p{background-color:var(--ds-background-accent-magenta-subtler-hovered,#f797d2)}
._bfhk1rd4{background-color:var(--ds-background-warning-hovered,#f8e6a0)}
._bfhk1rsu{background-color:var(--ds-background-accent-green-subtle,#4bce97)}
._bfhk1rtt{background-color:var(--ds-background-accent-red-subtler,#ffd5d2)}
._bfhk1s0z{background-color:var(--ds-background-information-pressed,#85b8ff)}
._bfhk1sou{background-color:var(--ds-background-accent-red-subtle-hovered,#fd9891)}
._bfhk1t05{background-color:var(--ds-background-accent-gray-subtle,#8590a2)}
._bfhk1t1b{background-color:var(--ds-background-accent-gray-bolder,#626f86)}
._bfhk1tf4{background-color:var(--ds-background-accent-red-subtle-pressed,#ffd5d2)}
._bfhk1tzq{background-color:var(--ds-background-warning,#fff7d6)}
._bfhk1uh7{background-color:var(--ds-background-accent-blue-subtler-pressed,#579dff)}
._bfhk1uro{background-color:var(--ds-background-accent-purple-bolder-pressed,#352c63)}
._bfhk1uwk{background-color:var(--ds-background-brand-boldest-hovered,#09326c)}
._bfhk1v33{background-color:var(--ds-background-accent-gray-subtler,#dcdfe4)}
._bfhk1vck{background-color:var(--ds-background-inverse-subtle,#00000029)}
._bfhk1vk5{background-color:var(--ds-background-accent-red-subtlest,#ffeceb)}
._bfhk1vop{background-color:var(--ds-background-accent-yellow-bolder,#946f00)}
._bfhk1vps{background-color:var(--ds-background-information-hovered,#cce0ff)}
._bfhk1w72{background-color:var(--ds-background-discovery-bold-hovered,#5e4db2)}
._bfhk1wnw{background-color:var(--ds-background-accent-green-subtler,#baf3db)}
._bfhk1wob{background-color:var(--ds-background-accent-purple-subtle-hovered,#b8acf6)}
._bfhk1wyu{background-color:var(--ds-background-accent-purple-subtler-hovered,#b8acf6)}
._bfhk1xmr{background-color:var(--ds-background-accent-blue-subtlest-pressed,#85b8ff)}
._bfhk1xp7{background-color:var(--ds-background-discovery-hovered,#dfd8fd)}
._bfhk1ya0{background-color:var(--ds-background-accent-orange-subtlest-pressed,#fec195)}
._bfhk1yvi{background-color:var(--ds-background-danger-hovered,#ffd5d2)}
._bfhk1z0s{background-color:var(--ds-background-accent-gray-subtle-pressed,#dcdfe4)}
._bfhk2c8p{background-color:var(--ds-background-accent-teal-bolder,#227d9b)}
._bfhk2ggb{background-color:var(--ds-background-brand-subtlest-pressed,#85b8ff)}
._bfhk31d1{background-color:var(--ds-background-accent-yellow-subtlest-pressed,#f5cd47)}
._bfhk32kj{background-color:var(--ds-background-accent-yellow-bolder-pressed,#533f04)}
._bfhk33gi{background-color:var(--ds-background-information,#e9f2ff)}
._bfhk3fv2{background-color:var(--ds-background-accent-yellow-subtler,#f8e6a0)}
._bfhk3utz{background-color:var(--ds-background-accent-green-subtlest-hovered,#baf3db)}
._bfhk3wxy{background-color:var(--ds-background-brand-subtlest,#e9f2ff)}
._bfhk4v9p{background-color:var(--ds-background-accent-blue-subtler,#cce0ff)}
._bfhk5bth{background-color:var(--ds-background-information-bold-pressed,#09326c)}
._bfhk5fkp{background-color:var(--ds-background-accent-purple-subtler-pressed,#9f8fef)}
._bfhk69rw{background-color:var(--ds-background-accent-red-subtler-pressed,#f87168)}
._bfhk7wq0{background-color:var(--ds-blanket-selected,#388bff14)}
._bfhk7xuz{background-color:var(--ds-background-accent-teal-subtlest,#e7f9ff)}
._bfhk82ra{background-color:var(--ds-background-accent-gray-bolder-pressed,#2c3e5d)}
._bfhk8emd{background-color:var(--ds-background-success-bold,#1f845a)}
._bfhk8h4p{background-color:var(--ds-background-accent-green-subtle-pressed,#baf3db)}
._bfhk8tvm{background-color:var(--ds-background-accent-magenta-subtlest,#ffecf8)}
._bfhk9pqq{background-color:var(--ds-background-danger-bold-pressed,#5d1f1a)}
._bfhka5yi{background-color:var(--ds-background-accent-green-bolder-hovered,#216e4e)}
._bfhkadgs{background-color:var(--ds-background-accent-purple-bolder-hovered,#5e4db2)}
._bfhkavxe{background-color:var(--ds-background-accent-blue-subtlest,#e9f2ff)}
._bfhkay3p{background-color:var(--ds-background-accent-blue-subtlest-hovered,#cce0ff)}
._bfhkb29m{background-color:var(--ds-background-brand-bold,#0c66e4)}
._bfhkbq5w{background-color:var(--ds-background-accent-lime-subtle,#94c748)}
._bfhkc3uk{background-color:var(--ds-background-accent-magenta-bolder,#ae4787)}
._bfhkc8cv{background-color:var(--ds-background-accent-blue-bolder,#0c66e4)}
._bfhkcdhy{background-color:var(--ds-background-neutral-bold,#44546f)}
._bfhkchd4{background-color:var(--ds-elevation-surface-current,#fff)}
._bfhkcrcn{background-color:var(--ds-background-success-bold-pressed,#164b35)}
._bfhkd4y8{background-color:var(--ds-background-accent-lime-subtlest-hovered,#d3f1a7)}
._bfhkd8a2{background-color:var(--ds-background-accent-gray-subtler-pressed,#8590a2)}
._bfhkdh45{background-color:var(--ds-background-accent-purple-subtle,#9f8fef)}
._bfhkdlkp{background-color:var(--ds-background-discovery-pressed,#b8acf6)}
._bfhke4nv{background-color:var(--ds-background-accent-gray-bolder-hovered,#44546f)}
._bfhkezq1{background-color:var(--ds-background-brand-subtlest-hovered,#cce0ff)}
._bfhkf2vu{background-color:var(--ds-background-accent-orange-subtler,#fedec8)}
._bfhkf767{background-color:var(--ds-background-accent-orange-bolder-pressed,#702e00)}
._bfhkf7u5{background-color:var(--ds-surface-raised-pressed,#dcdfe4)}
._bfhkfg4m{background-color:var(--ds-background-selected,#e9f2ff)}
._bfhkfoww{background-color:var(--ds-background-accent-lime-subtle-hovered,#b3df72)}
._bfhkg4tm{background-color:var(--ds-background-accent-orange-subtlest-hovered,#fedec8)}
._bfhkg5py{background-color:var(--ds-background-accent-teal-subtler-hovered,#9dd9ee)}
._bfhkgr1v{background-color:var(--ds-background-selected-bold-pressed,#09326c)}
._bfhkgslw{background-color:var(--ds-background-accent-purple-subtlest-pressed,#b8acf6)}
._bfhkh3tw{background-color:var(--ds-skeleton-subtle,#091e4208)}
._bfhkhcrq{background-color:var(--ds-background-accent-blue-bolder-pressed,#09326c)}
._bfhkhgj8{background-color:var(--ds-background-accent-lime-subtle-pressed,#d3f1a7)}
._bfhkhi52{background-color:var(--ds-background-accent-orange-bolder-hovered,#a54800)}
._bfhkhp5a{background-color:var(--ds-surface-raised,#fff)}
._bfhki1yw{background-color:var(--ds-background-selected-hovered,#cce0ff)}
._bfhkip91{background-color:var(--ds-background-neutral-pressed,#091e424f)}
._bfhkjgng{background-color:var(--ds-background-accent-orange-bolder,#c25100)}
._bfhkkjqf{background-color:var(--ds-background-accent-yellow-bolder-hovered,#7f5f01)}
._bfhkkrt9{background-color:var(--ds-background-accent-red-subtle,#f87168)}
._bfhkl3ve{background-color:var(--ds-background-accent-teal-bolder-hovered,#206a83)}
._bfhklg04{background-color:var(--ds-background-accent-yellow-subtler-hovered,#f5cd47)}
._bfhkm0d5{background-color:var(--ds-background-accent-purple-subtlest-hovered,#dfd8fd)}
._bfhkm7j4{background-color:var(--ds-background-neutral,#091e420f)}
._bfhkm890{background-color:var(--ds-background-accent-lime-subtlest,#efffd6)}
._bfhkmpja{background-color:var(--ds-background-inverse-subtle-hovered,#0000003d)}
._bfhkmv6i{background-color:var(--ds-background-success,#dcfff1)}
._bfhkn729{background-color:var(--ds-background-accent-green-subtlest,#dcfff1)}
._bfhkn82g{background-color:var(--ds-skeleton,#091e420f)}
._bfhknzpa{background-color:var(--ds-background-accent-lime-bolder-hovered,#4c6b1f)}
._bfhko0y5{background-color:var(--ds-background-accent-yellow-subtle-pressed,#cf9f02)}
._bfhkp7j4{background-color:var(--ds-background-accent-purple-subtlest,#f3f0ff)}
._bfhkprmn{background-color:var(--ds-background-accent-red-bolder-pressed,#5d1f1a)}
._bfhkq7li{background-color:var(--ds-background-accent-teal-subtler-pressed,#6cc3e0)}
._bfhkqbzx{background-color:var(--ds-surface-sunken,#f7f8f9)}
._bfhkqctv{background-color:var(--ds-background-accent-blue-subtle-pressed,#cce0ff)}
._bfhkqkzo{background-color:var(--ds-background-brand-bold-pressed,#09326c)}
._bfhkr01l{background-color:var(--ds-background-input-pressed,#fff)}
._bfhkr3zd{background-color:var(--ds-background-accent-magenta-subtlest-hovered,#fdd0ec)}
._bfhkrb53{background-color:var(--ds-background-discovery-bold-pressed,#352c63)}
._bfhkrj9s{background-color:var(--ds-background-warning-bold-pressed,#cf9f02)}
._bfhkrprw{background-color:var(--ds-blanket-danger,#ef5c4814)}
._bfhkrw5d{background-color:var(--ds-background-accent-red-subtler-hovered,#fd9891)}
._bfhkrwyz{background-color:var(--ds-background-accent-magenta-subtle-hovered,#f797d2)}
._bfhkry5t{background-color:var(--ds-background-accent-yellow-subtle,#f5cd47)}
._bfhks9hg{background-color:var(--ds-background-danger-bold-hovered,#ae2e24)}
._bfhkse6l{background-color:var(--ds-background-accent-teal-bolder-pressed,#164555)}
._bfhksm61{background-color:var(--ds-background-neutral-subtle,#00000000)}
._bfhksyzs{background-color:var(--ds-background-disabled,#091e4208)}
._bfhktde4{background-color:var(--ds-background-accent-lime-bolder,#5b7f24)}
._bfhktdv8{background-color:var(--ds-background-accent-teal-subtle,#6cc3e0)}
._bfhku1se{background-color:var(--ds-background-brand-bold-hovered,#05c)}
._bfhkuibq{background-color:var(--ds-background-accent-red-bolder,#c9372c)}
._bfhkung8{background-color:var(--ds-background-accent-green-subtler-hovered,#7ee2b8)}
._bfhkuxsx{background-color:var(--ds-background-accent-orange-subtler-pressed,#fea362)}
._bfhkv5yf{background-color:var(--ds-background-accent-green-subtlest-pressed,#7ee2b8)}
._bfhkv9ra{background-color:var(--ds-background-accent-magenta-subtler,#fdd0ec)}
._bfhkvq0a{background-color:var(--ds-surface-overlay-pressed,#dcdfe4)}
._bfhkvuon{background-color:var(--ds-surface,#fff)}
._bfhkwaw3{background-color:var(--ds-background-accent-magenta-bolder-pressed,#50253f)}
._bfhkwz05{background-color:var(--ds-background-accent-purple-subtle-pressed,#dfd8fd)}
._bfhkxuzc{background-color:var(--ds-background-accent-magenta-subtler-pressed,#e774bb)}
._bfhky4f1{background-color:var(--ds-background-success-pressed,#7ee2b8)}
._bfhkybec{background-color:var(--ds-background-danger-bold,#c9372c)}
._bfhkygwo{background-color:var(--ds-background-accent-purple-bolder,#6e5dc6)}
._bfhkz2ec{background-color:var(--ds-background-accent-lime-subtler,#d3f1a7)}
._bfhkzh8h{background-color:var(--ds-background-accent-teal-subtle-hovered,#9dd9ee)}
._bozg12x7{padding-inline-start:var(--ds-space-075,6px)}
._bozg1b66{padding-inline-start:var(--ds-space-050,4px)}
._bozg1ejb{padding-inline-start:var(--ds-space-300,24px)}
._bozg1epz{padding-inline-start:var(--ds-space-1000,5pc)}
._bozg1jfw{padding-inline-start:var(--ds-space-500,40px)}
._bozgoahv{padding-inline-start:var(--ds-space-600,3pc)}
._bozgpxbi{padding-inline-start:var(--ds-space-200,1pc)}
._bozgu2gc{padding-inline-start:var(--ds-space-100,8px)}
._bozgutpp{padding-inline-start:var(--ds-space-150,9pt)}
._bozgv47k{padding-inline-start:var(--ds-space-250,20px)}
._bozgv77o{padding-inline-start:var(--ds-space-025,2px)}
._bozgxncg{padding-inline-start:var(--ds-space-800,4pc)}
._bozgxy5q{padding-inline-start:var(--ds-space-400,2pc)}
._bozgze3t{padding-inline-start:var(--ds-space-0,0)}
._r06hglyw{-webkit-appearance:none;appearance:none}
._vchhusvi{box-sizing:border-box}
._y4ti12x7{padding-inline-end:var(--ds-space-075,6px)}
._y4ti1b66{padding-inline-end:var(--ds-space-050,4px)}
._y4ti1ejb{padding-inline-end:var(--ds-space-300,24px)}
._y4ti1epz{padding-inline-end:var(--ds-space-1000,5pc)}
._y4ti1jfw{padding-inline-end:var(--ds-space-500,40px)}
._y4tioahv{padding-inline-end:var(--ds-space-600,3pc)}
._y4tipxbi{padding-inline-end:var(--ds-space-200,1pc)}
._y4tiu2gc{padding-inline-end:var(--ds-space-100,8px)}
._y4tiutpp{padding-inline-end:var(--ds-space-150,9pt)}
._y4tiv47k{padding-inline-end:var(--ds-space-250,20px)}
._y4tiv77o{padding-inline-end:var(--ds-space-025,2px)}
._y4tixncg{padding-inline-end:var(--ds-space-800,4pc)}
._y4tixy5q{padding-inline-end:var(--ds-space-400,2pc)}
._y4tize3t{padding-inline-end:var(--ds-space-0,0)}