._11c8qk37{font:var(--ds-font-body-UNSAFE_small,normal 400 9pt/1pc ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Ubuntu,system-ui,"Helvetica Neue",sans-serif)}
._1yt4nmcz{padding:var(--_a5vo3x)}
._2rko1sit{border-radius:var(--ds-border-radius,3px)}
._152tze3t{inset-block-start:var(--ds-space-0,0)}
._18m915vq{overflow-y:hidden}
._1bto1l2s{text-overflow:ellipsis}
._1e02ze3t{inset-inline-start:var(--ds-space-0,0)}
._1i4q1hna{overflow-wrap:break-word}
._1reo15vq{overflow-x:hidden}
._bfhkpioe{background-color:var(--_yr7xfv)}
._o5721q9c{white-space:nowrap}
._p12f1pna{max-width:420px}
._p12fp3fh{max-width:15pc}
._slp31hna{word-wrap:break-word}
._syaz1i9p{color:var(--_ywovvi)}
._vchhusvi{box-sizing:border-box}