
._13xajb7m [role=tab]{padding:var(--_1xs0znw)}
._17hygqwt [role=tab]:hover:after{border-radius:var(--ds-border-radius-050,2px)}
._1jakgqwt [role=tab]:active:after{border-radius:var(--ds-border-radius-050,2px)}
._1yt4ze3t{padding:var(--ds-space-0,0)}
._9r9ugqwt [role=tab][aria-selected=true]:after{border-radius:var(--ds-border-radius-050,2px)}
._rfx3gqwt:before{border-radius:var(--ds-border-radius-050,2px)}
._1b8d1hjo [role=tab]:active:after{border-block-end:var(--_1nddtxm)}
._1b8dpl7t [role=tab]:active:after{border-block-end:var(--_1b4y4br)}
._1lzu1hjo [role=tab]:hover:after{border-block-end:var(--_1nddtxm)}
._1lzupl7t [role=tab]:hover:after{border-block-end:var(--_1b4y4br)}
._rsmz8hos [role=tab][aria-selected=true]:after{border-block-end:var(--_1rlpysg)}
._rsmzmfou [role=tab][aria-selected=true]:after{border-block-end:var(--_hdabma)}._10vzidpf [role=tab]{margin-right:0}
._140sidpf [role=tab]:hover:after{height:0}
._15d8b3bt [role=tab]:hover:after{content:""}
._15plidpf [role=tab]{margin-left:0}
._166qidpf [role=tab]:hover:after{margin-left:0}
._16u6b3bt [role=tab]:active:after{content:""}
._170tidpf [role=tab]:active:after{margin-right:0}
._1c9uu2gc [role=tab]:active:after{inset-inline-start:var(--ds-space-100,8px)}
._1cs8stnw:before{position:absolute}
._1dze1l2s [role=tab]{text-overflow:ellipsis}
._1e0c1txw{display:flex}
._1enwidpf:before{inset-inline-end:0}
._1fkridpf:before{inset-block-end:0}
._1fud15vq [role=tab]{overflow-y:hidden}
._1fztidpf [role=tab]:hover:after{inset-block-end:0}
._1jjcu2gc [role=tab][aria-selected=true]:after{inset-inline-start:var(--ds-space-100,8px)}
._1ksob3bt [role=tab][aria-selected=true]:after{content:""}
._1kt9b3bt:before{content:""}
._1lbou2gc [role=tab]:active:after{inset-inline-end:var(--ds-space-100,8px)}
._1m0e1kw7 [role=tab][aria-selected=true]:after{width:inherit}
._1mbxidpf [role=tab][aria-selected=true]:after{margin-right:0}
._1mmwidpf [role=tab]{margin-bottom:0}
._1mp41kw7:before{width:inherit}
._1n121kw7 [role=tab]:active:after{width:inherit}
._1nvfidpf [role=tab]:active:after{margin-left:0}
._1olcu2gc [role=tab]:hover:after{inset-inline-start:var(--ds-space-100,8px)}
._1p9sidpf [role=tab][aria-selected=true]:after{inset-block-end:0}
._1qa1u2gc [role=tab][aria-selected=true]:after{inset-inline-end:var(--ds-space-100,8px)}
._1quz1425 [role=tab]{color:var(--_13a5t4u)}
._1russudh:before{background-color:var(--_lvpq93)}
._1sglstnw [role=tab][aria-selected=true]:after{position:absolute}
._1tms1q9c [role=tab]{white-space:nowrap}
._1xrmidpf [role=tab]:hover:after{margin-right:0}
._1yk1idpf [role=tab]:active:after{inset-block-end:0}
._24g71kw7 [role=tab]:hover:after{width:inherit}
._7hip15vq [role=tab]{overflow-x:hidden}
._7p9oidpf [role=tab]:active:after{height:0}
._93pdidpf [role=tab][aria-selected=true]:after{height:0}
._ahbqx0bf{margin-inline-start:var(--ds-space-negative-100,-8px)}
._bb0mh2mm [role=tab]{position:relative}
._bppridpf [role=tab][aria-selected=true]:after{margin-top:0}
._eid3idpf:before{margin-right:0}
._fiemln51 [role=tab][aria-selected=true]{color:var(--_1hfkvbo)}
._fiizidpf [role=tab]:hover:after{margin-top:0}
._fntnidpf:before{margin-left:0}
._gpbcidpf [role=tab]{margin-top:0}
._k48p1wq8{font-weight:var(--ds-font-weight-medium,500)}
._kfgt1bk5:before{height:var(--ds-border-width-outline,2px)}
._kfgte4h9:before{height:var(--ds-border-width,1px)}
._kn0bidpf [role=tab][aria-selected=true]:after{margin-bottom:0}
._kqswh2mm{position:relative}
._lycustnw [role=tab]:hover:after{position:absolute}
._o2e1stnw [role=tab]:active:after{position:absolute}
._pascidpf:before{margin-top:0}
._rzxytlke [role=tab]{cursor:pointer}
._w9ewidpf [role=tab]:active:after{margin-top:0}
._wd7eu2gc [role=tab]:hover:after{inset-inline-end:var(--ds-space-100,8px)}
._wsgdidpf [role=tab][aria-selected=true]:after{margin-left:0}
._xyihidpf [role=tab]:hover:after{margin-bottom:0}
._y1g1idpf [role=tab]:active:after{margin-bottom:0}
._z5wtu2gc:before{inset-inline-start:var(--ds-space-100,8px)}
._zr3eidpf:before{margin-bottom:0}
._1oaz1fgx [role=tab]:hover{color:var(--_kkbq40)}
._1i2072d1 [role=tab]:active{color:var(--_71dbsd)}