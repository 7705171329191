._11c81doa{font:var(--ds-font-body-large,normal 400 1pc/24px ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Ubuntu,system-ui,"Helvetica Neue",sans-serif)}
._11c81oud{font:var(--ds-font-body,normal 400 14px/20px ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Ubuntu,system-ui,"Helvetica Neue",sans-serif)}
._11c81vlj{font:var(--ds-font-body-small,normal 400 11px/1pc ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Ubuntu,system-ui,"Helvetica Neue",sans-serif)}
._11c8qk37{font:var(--ds-font-body-UNSAFE_small,normal 400 9pt/1pc ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Ubuntu,system-ui,"Helvetica Neue",sans-serif)}
._18m915vq{overflow-y:hidden}
._18u0idpf{margin-left:0}
._19pkidpf{margin-top:0}
._1e0ccj1k{display:-webkit-box}
._1i4qfg65{overflow-wrap:anywhere}
._1nmz9jpi{word-break:break-all}
._1reo15vq{overflow-x:hidden}
._2hwxidpf{margin-right:0}
._k48p1pd9{font-weight:var(--ds-font-weight-semibold,600)}
._k48p1wq8{font-weight:var(--ds-font-weight-medium,500)}
._k48pi7a9{font-weight:var(--ds-font-weight-regular,400)}
._k48pmoej{font-weight:var(--ds-font-weight-bold,700)}
._otyridpf{margin-bottom:0}
._sudp1e54{-webkit-box-orient:vertical}
._syaz10m5{color:var(--ds-text-accent-purple,#5e4db2)}
._syaz12v7{color:var(--ds-text-accent-teal-bolder,#164555)}
._syaz131l{color:var(--ds-text-subtlest,#626f86)}
._syaz14q2{color:var(--ds-link,#0c66e4)}
._syaz15cr{color:var(--ds-text-inverse,#fff)}
._syaz16c2{color:var(--ds-link-pressed,#05c)}
._syaz17qg{color:var(--ds-text-accent-teal,#206a83)}
._syaz1dyx{color:var(--ds-text-accent-gray,#44546f)}
._syaz1e6v{color:var(--ds-text-brand,#0c66e4)}
._syaz1fxt{color:var(--ds-text,#172b4d)}
._syaz1gjq{color:var(--ds-text-subtle,#44546f)}
._syaz1ik3{color:var(--ds-text-accent-lime-bolder,#37471f)}
._syaz1lh4{color:var(--ds-text-disabled,#091e424f)}
._syaz1q28{color:var(--ds-text-accent-orange,#a54800)}
._syaz1qur{color:var(--ds-text-accent-magenta-bolder,#50253f)}
._syaz1s8m{color:var(--ds-text-accent-magenta,#943d73)}
._syaz1tco{color:var(--ds-text-accent-lime,#4c6b1f)}
._syaz1tmw{color:var(--ds-text-danger,#ae2e24)}
._syaz1vps{color:var(--ds-text-accent-orange-bolder,#702e00)}
._syaz1x9z{color:var(--ds-text-accent-gray-bolder,#091e42)}
._syaz1y78{color:var(--ds-text-accent-green-bolder,#164b35)}
._syaz2ibx{color:var(--ds-text-accent-purple-bolder,#352c63)}
._syaz9lu1{color:var(--ds-text-accent-red,#ae2e24)}
._syazal3n{color:var(--ds-text-warning-inverse,#172b4d)}
._syazaqb7{color:var(--ds-text-selected,#0c66e4)}
._syaze0os{color:var(--ds-text-information,#05c)}
._syazekll{color:var(--ds-text-accent-yellow-bolder,#533f04)}
._syazfb2s{color:var(--ds-text-accent-yellow,#7f5f01)}
._syazg3x0{color:var(--ds-link-visited-pressed,#352c63)}
._syazh55r{color:var(--ds-text-accent-green,#216e4e)}
._syazhwvp{color:var(--ds-text-accent-red-bolder,#5d1f1a)}
._syazpnps{color:var(--ds-text-accent-blue,#05c)}
._syazsc1o{color:var(--ds-text-accent-blue-bolder,#09326c)}
._syazw7te{color:var(--ds-link-visited,#5e4db2)}
._syazwx40{color:var(--ds-text-warning,#a54800)}
._syazy73q{color:var(--ds-text-success,#216e4e)}
._syazzjem{color:var(--ds-text-discovery,#5e4db2)}
._y3gn1h6o{text-align:center}
._y3gnh9n0{text-align:end}
._y3gnv2br{text-align:start}
._zg8l1m30{font-style:italic}