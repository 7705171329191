._1bah1b1v{justify-content:space-around}
._1bah1fhb{justify-content:stretch}
._1bah1h6o{justify-content:center}
._1bah1yb4{justify-content:space-between}
._1bahh9n0{justify-content:end}
._1bahitcz{justify-content:space-evenly}
._1bahv2br{justify-content:start}
._1e0c1txw{display:flex}
._1n261g80{flex-wrap:wrap}
._1n261q9c{flex-wrap:nowrap}
._1p5712x7{row-gap:var(--ds-space-075,6px)}
._1p571b66{row-gap:var(--ds-space-050,4px)}
._1p571ejb{row-gap:var(--ds-space-300,24px)}
._1p571epz{row-gap:var(--ds-space-1000,5pc)}
._1p571jfw{row-gap:var(--ds-space-500,40px)}
._1p57oahv{row-gap:var(--ds-space-600,3pc)}
._1p57pxbi{row-gap:var(--ds-space-200,1pc)}
._1p57u2gc{row-gap:var(--ds-space-100,8px)}
._1p57utpp{row-gap:var(--ds-space-150,9pt)}
._1p57v47k{row-gap:var(--ds-space-250,20px)}
._1p57v77o{row-gap:var(--ds-space-025,2px)}
._1p57xncg{row-gap:var(--ds-space-800,4pc)}
._1p57xy5q{row-gap:var(--ds-space-400,2pc)}
._1p57ze3t{row-gap:var(--ds-space-0,0)}
._2lx21bp4{flex-direction:column}
._2lx2vrvc{flex-direction:row}
._4cvr1fhb{align-items:stretch}
._4cvr1h6o{align-items:center}
._4cvr1q9y{align-items:baseline}
._4cvrh9n0{align-items:end}
._4cvrv2br{align-items:start}
._gy1p12x7{column-gap:var(--ds-space-075,6px)}
._gy1p1b66{column-gap:var(--ds-space-050,4px)}
._gy1p1ejb{column-gap:var(--ds-space-300,24px)}
._gy1p1epz{column-gap:var(--ds-space-1000,5pc)}
._gy1p1jfw{column-gap:var(--ds-space-500,40px)}
._gy1poahv{column-gap:var(--ds-space-600,3pc)}
._gy1ppxbi{column-gap:var(--ds-space-200,1pc)}
._gy1pu2gc{column-gap:var(--ds-space-100,8px)}
._gy1putpp{column-gap:var(--ds-space-150,9pt)}
._gy1pv47k{column-gap:var(--ds-space-250,20px)}
._gy1pv77o{column-gap:var(--ds-space-025,2px)}
._gy1pxncg{column-gap:var(--ds-space-800,4pc)}
._gy1pxy5q{column-gap:var(--ds-space-400,2pc)}
._gy1pze3t{column-gap:var(--ds-space-0,0)}
._vchhusvi{box-sizing:border-box}